import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { createServicepredict } from '../../graphql/mutations'
import { getServicepredict } from '../../graphql/queries';
import { Auth, API, graphqlOperation } from 'aws-amplify';

function ServiceFormPage (props){
    const navigate = useNavigate()
    
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const [BPSDshow, setBPSDShow] = useState(false)
    const handleBPSDClose = () => setBPSDShow(false)
    const handleBPSDOpen = () => setBPSDShow(true)

    const getUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            setField('username',user.username)
        } catch (err) {
            console.error(err)
        }
    }

    const getServiceForm = async () => {
        try{
            const user = await Auth.currentAuthenticatedUser()
            const listData = await API.graphql(graphqlOperation(getServicepredict,{account:user.username}))
        }catch(err){
            console.log('fail')
            console.error(err)
        }
    }

    const setField = (field, value) =>{
        setForm({
            ...form,
            [field]: value
        })
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            })
        }
    }

    const findFormErrors = () => {
        const {username, B3, VISION, HEARING, E_WEAR, E_URINE, E_TOILET, E_MOVE, E_WALK, CLEANING, 
                G1A, G3A, TALL, WEIGH, G4C, G4D3, G4F, G8B, G8C, G8E, H2A, SOLITARY, JJ1, J2, K2, 
                K4, K5, K6, K7, AGE, A3CH1, LABOR_TYPE} = form
        const newErrors = {}

        if (!B3 || B3 === '' ){
            newErrors.B3 = '此欄位不能空白'
        }else if (B3 < 18 || B3 > 120){
            newErrors.B3 = '請介於18~120之間'
        }else if (!B3.match(/(^(?=.*\d).{2,4}$)/)){
            newErrors.B3 = '請不要包含任何英文或特殊符號'
        }

        if (!VISION || VISION === '' ){
            newErrors.VISION = '請選擇您的答案'
        }

        if (!HEARING || HEARING === '' ){
            newErrors.HEARING = '請選擇您的答案'
        }

        if (!E_WEAR || E_WEAR === '' ){
            newErrors.E_WEAR = '請選擇您的答案'
        }

        if (!E_URINE || E_URINE === '' ){
            newErrors.E_URINE = '請選擇您的答案'
        }

        if (!E_TOILET || E_TOILET === '' ){
            newErrors.E_TOILET = '請選擇您的答案'
        }

        if (!E_MOVE || E_MOVE === '' ){
            newErrors.E_MOVE = '請選擇您的答案'
        }

        if (!E_WALK || E_WALK === '' ){
            newErrors.E_WALK = '請選擇您的答案'
        }

        if (!CLEANING || CLEANING === '' ){
            newErrors.CLEANING = '請選擇您的答案'
        }

        if (!G1A || G1A === '' ){
            newErrors.G1A = '請選擇您的答案'
        }

        if (!G3A || G3A === '' ){
            newErrors.G3A = '請選擇您的答案'
        }

        if (!TALL || TALL === '' ){
            newErrors.TALL = '請輸入個案的身高'
        }else if (TALL < 140 || TALL > 250){
            newErrors.TALL = '請介於140~250之間'
        }else if (!TALL.match(/(^(?=.*\d).{3,3}$)/)){
            newErrors.TALL = '請不要包含任何英文或特殊符號，且介於140~250之間'
        }

        if (!WEIGH || WEIGH === '' ){
            newErrors.WEIGH = '請輸入個案的體重'
        }else if (WEIGH < 30 || WEIGH > 400){
            newErrors.WEIGH = '請介於30~400之間'
        }else if (!WEIGH.match(/(^(?=.*\d).{2,3}$)/)){
            newErrors.WEIGH = '請不要包含任何英文或特殊符號，且介於30~400之間'
        }

        if (!G4C || G4C === '' ){
            newErrors.G4C = '請選擇您的答案'
        }

        if (!G4D3 || G4D3 === '' ){
            newErrors.G4D3 = '請選擇您的答案'
        }

        if (!G4F || G4F === '' ){
            newErrors.G4F = '請選擇您的答案'
        }

        if (!G8B || G8B === '' ){
            newErrors.G8B = '請選擇您的答案'
        }

        if (!G8C || G8C === '' ){
            newErrors.G8C = '請選擇您的答案'
        }

        if (!G8E || G8E === '' ){
            newErrors.G8E = '請選擇您的答案'
        }

        if (!H2A || H2A === '' ){
            newErrors.H2A = '請選擇您的答案'
        }

        if (!SOLITARY || SOLITARY === '' ){
            newErrors.SOLITARY = '請選擇您的答案'
        }
        
        if (!JJ1 || JJ1 === '' ){
            newErrors.JJ1 = '請選擇您的答案'
        }

        if (!J2 || J2 === '' ){
            newErrors.J2 = '請選擇您的答案'
        }

        if (!K2 || K2 === '' ){
            newErrors.K2 = '請選擇您的答案'
        }

        if (!K4 || K4 === '' ){
            newErrors.K4 = '請輸入您的照顧時間'
        }else if (K4 < 1 || K4 > 999){
            newErrors.K4 = '請介於1~999之間'
        }else if (!K4.match(/(^(?=.*\d).{1,3}$)/)){
            newErrors.K4 = '請不要包含任何英文或特殊符號'
        }

        if (!K5 || K5 === '' ){
            newErrors.K5 = '請選擇您的答案'
        }

        if (!K6 || K6 === '' ){
            newErrors.K6 = '請選擇您的答案'
        }

        if (!K7 || K7 === '' ){
            newErrors.K7 = '請選擇您的答案'
        }

        if (!AGE || AGE === '' ){
            newErrors.AGE = '此欄位不能空白'
        }else if (AGE < 18 || AGE > 120){
            newErrors.AGE = '請介於18~120之間'
        }else if (!AGE.match(/(^(?=.*\d).{2,4}$)/)){
            newErrors.AGE = '請不要包含任何英文或特殊符號'
        }

        if (!A3CH1 || A3CH1 === '' ){
            newErrors.A3CH1 = '請選擇您的答案'
        }

        if (!LABOR_TYPE || LABOR_TYPE === '' ){
            newErrors.LABOR_TYPE = '請選擇您的答案'
        }


        return newErrors
      }
    
    useEffect(() => {
        {
            getUser()
            getServiceForm()
            props.isAuthenticated !== true && (
                navigate('/')
            )
        }
    }, []);

    const handleServiceForm = async () => {
        const newErrors = findFormErrors()
        if ( Object.keys(newErrors).length > 0 ){
            setErrors(newErrors)
        }else{
            try {
                const { username, B3, VISION, HEARING, E_WEAR, E_URINE, E_TOILET, E_MOVE, E_WALK, CLEANING, 
                        G1A, G3A, TALL, WEIGH, G4C, G4D3, G4F, G8B, G8C, G8E, H2A, SOLITARY, JJ1, J2, K2,
                        K4, K5, K6, K7, AGE, A3CH1, LABOR_TYPE} = form
                const newformdata = {
                    account: username,
                    B3,
                    VISION,
                    HEARING,
                    E_WEAR,
                    E_URINE,
                    E_TOILET,
                    E_MOVE,
                    E_WALK,
                    CLEANING,
                    G1A,
                    G3A,
                    TALL,
                    WEIGH,
                    G4C,
                    G4D3,
                    G4F,
                    G8B,
                    G8C,
                    G8E,
                    H2A,
                    SOLITARY,
                    JJ1,
                    J2,
                    K2,
                    K4,
                    K5,
                    K6,
                    K7,
                    AGE,
                    A3CH1,
                    LABOR_TYPE,
                    storestate: false
                }
                console.log('submit')
                await API.graphql(graphqlOperation(createServicepredict, {input: newformdata}));
                navigate('/servicepredict')
            } catch (err) { 
                console.log('error') 
                console.error(err) 
            }
        }
    }

    return(
        <Container>
            <Row className="px-4 my-5">
                <Col><h1>服務預測問卷</h1></Col>
            </Row>
            <Row className="px-4 my-5">
                <Col sm={10}>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>1.請輸入照顧者的年齡</Form.Label>
                            <Form.Control type="text" required
                                onChange={evt => setField('B3',evt.target.value)}
                                isInvalid={!!errors.B3}/>
                            <Form.Control.Feedback type='invalid'>{ errors.B3 }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Label>2.照顧者睡眠受到干擾(例如：需在夜間照顧經常要上落床或無法安頓下來的病者)</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="是" 
                                name="JJ1" 
                                value={1}
                                onChange={evt => setField('JJ1',evt.target.value)}
                                isInvalid={!!errors.JJ1}
                                feedback={errors.JJ1}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="否" 
                                name="JJ1"  
                                value={0}
                                onChange={evt => setField('JJ1',evt.target.value)}
                                isInvalid={!!errors.JJ1}
                                feedback={errors.JJ1}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.JJ1}
                                feedback={errors.JJ1}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>3.照顧者體力上的負荷(例如：需專注看護病者或費力去幫助病者坐立)</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="是" 
                                name="J2" 
                                value={1}
                                onChange={evt => setField('J2',evt.target.value)}
                                isInvalid={!!errors.J2}
                                feedback={errors.J2}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="否" 
                                name="J2"  
                                value={0}
                                onChange={evt => setField('J2',evt.target.value)}
                                isInvalid={!!errors.J2}
                                feedback={errors.J2}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.J2}
                                feedback={errors.J2}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>4.若您有事必須外出，一天之中（二十四小時），請問您可將被照顧者單獨留置家中幾小時沒有問題</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="九小時以上" 
                                name="K2" 
                                value={6}
                                onChange={evt => setField('K2',evt.target.value)}
                                isInvalid={!!errors.K2}
                                feedback={errors.K2}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="六小時～未滿九小時" 
                                name="K2"  
                                value={5}
                                onChange={evt => setField('K2',evt.target.value)}
                                isInvalid={!!errors.K2}
                                feedback={errors.K2}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="三小時～未滿六小時" 
                                name="K2"  
                                value={4}
                                onChange={evt => setField('K2',evt.target.value)}
                                isInvalid={!!errors.K2}
                                feedback={errors.K2}
                            />
                            <br/>
                            <Form.Check 
                                inline
                                type="radio" 
                                label="一小時～未滿三小時" 
                                name="K2"  
                                value={3}
                                onChange={evt => setField('K2',evt.target.value)}
                                isInvalid={!!errors.K2}
                                feedback={errors.K2}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="未滿一小時" 
                                name="K2"  
                                value={2}
                                onChange={evt => setField('K2',evt.target.value)}
                                isInvalid={!!errors.K2}
                                feedback={errors.K2}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="被照顧者不能一個人在家" 
                                name="K2"  
                                value={1}
                                onChange={evt => setField('K2',evt.target.value)}
                                isInvalid={!!errors.K2}
                                feedback={errors.K2}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.K2}
                                feedback={errors.K2}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>5.請問您已經照顧被照顧者多久(以月為單位)</Form.Label>
                            <Form.Control type="text" required
                                onChange={evt => setField('K4',evt.target.value)} 
                                isInvalid={!!errors.K4}/>
                            <Form.Control.Feedback type='invalid'>{ errors.K4 }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Label>6.過去一個月內，您覺得您健康狀況好不好</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="非常不好" 
                                name="K5" 
                                value={6}
                                onChange={evt => setField('K5',evt.target.value)}
                                isInvalid={!!errors.K5}
                                feedback={errors.K5}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="不好" 
                                name="K5"  
                                value={5}
                                onChange={evt => setField('K5',evt.target.value)}
                                isInvalid={!!errors.K5}
                                feedback={errors.K5}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="尚可" 
                                name="K5"  
                                value={4}
                                onChange={evt => setField('K5',evt.target.value)}
                                isInvalid={!!errors.K5}
                                feedback={errors.K5}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="好" 
                                name="K5"  
                                value={3}
                                onChange={evt => setField('K5',evt.target.value)}
                                isInvalid={!!errors.K5}
                                feedback={errors.K5}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="很好" 
                                name="K5"  
                                value={2}
                                onChange={evt => setField('K5',evt.target.value)}
                                isInvalid={!!errors.K5}
                                feedback={errors.K5}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="非常好" 
                                name="K5"  
                                value={1}
                                onChange={evt => setField('K5',evt.target.value)}
                                isInvalid={!!errors.K5}
                                feedback={errors.K5}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.K5}
                                feedback={errors.K5}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>7.整體來說，您覺得您生活品質好不好</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="非常不好" 
                                name="K6" 
                                value={6}
                                onChange={evt => setField('K6',evt.target.value)}
                                isInvalid={!!errors.K6}
                                feedback={errors.K6}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="不好" 
                                name="K6"  
                                value={5}
                                onChange={evt => setField('K6',evt.target.value)}
                                isInvalid={!!errors.K6}
                                feedback={errors.K6}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="尚可" 
                                name="K6"  
                                value={4}
                                onChange={evt => setField('K6',evt.target.value)}
                                isInvalid={!!errors.K6}
                                feedback={errors.K6}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="好" 
                                name="K6"  
                                value={3}
                                onChange={evt => setField('K6',evt.target.value)}
                                isInvalid={!!errors.K6}
                                feedback={errors.K6}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="很好" 
                                name="K6"  
                                value={2}
                                onChange={evt => setField('K6',evt.target.value)}
                                isInvalid={!!errors.K6}
                                feedback={errors.K6}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="非常好" 
                                name="K6"  
                                value={1}
                                onChange={evt => setField('K6',evt.target.value)}
                                isInvalid={!!errors.K6}
                                feedback={errors.K6}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.K6}
                                feedback={errors.K6}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>8.您目前有無工作</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="有" 
                                name="K7" 
                                value={1}
                                onChange={evt => setField('K7',evt.target.value)}
                                isInvalid={!!errors.K7}
                                feedback={errors.K7}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="沒有" 
                                name="K7"  
                                value={0}
                                onChange={evt => setField('K7',evt.target.value)}
                                isInvalid={!!errors.K7}
                                feedback={errors.K7}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.K7}
                                feedback={errors.K7}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>9.是否有聘用外看</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="是" 
                                name="LABOR_TYPE" 
                                value={1}
                                onChange={evt => setField('LABOR_TYPE',evt.target.value)}
                                isInvalid={!!errors.LABOR_TYPE}
                                feedback={errors.LABOR_TYPE}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="否" 
                                name="LABOR_TYPE"  
                                value={0}
                                onChange={evt => setField('LABOR_TYPE',evt.target.value)}
                                isInvalid={!!errors.LABOR_TYPE}
                                feedback={errors.LABOR_TYPE}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.LABOR_TYPE}
                                feedback={errors.LABOR_TYPE}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>10.被照顧者的年齡</Form.Label>
                            <Form.Control type="text" required
                                onChange={evt => setField('AGE',evt.target.value)} 
                                isInvalid={!!errors.AGE}/>
                            <Form.Control.Feedback type='invalid'>{ errors.AGE }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Label>11.被照顧者是否為一般戶</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="是" 
                                name="A3CH1" 
                                value={1}
                                onChange={evt => setField('A3CH1',evt.target.value)}
                                isInvalid={!!errors.A3CH1}
                                feedback={errors.A3CH1}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="否" 
                                name="A3CH1"  
                                value={0}
                                onChange={evt => setField('A3CH1',evt.target.value)}
                                isInvalid={!!errors.A3CH1}
                                feedback={errors.A3CH1}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.A3CH1}
                                feedback={errors.A3CH1}
                                feedbackType="invalid"
                            />
                        </Form.Group>


                        <Form.Label>12.被照顧者的視力（在光線充足和戴眼鏡的情況下）</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="適當(能看到書報中的標準字體)" 
                                name="VISION" 
                                value={1}
                                onChange={evt => setField('VISION',evt.target.value)}
                                isInvalid={!!errors.VISION}
                                feedback={errors.VISION}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="輕度障礙(可以看清楚大字的印書體)" 
                                name="VISION"  
                                value={2}
                                onChange={evt => setField('VISION',evt.target.value)}
                                isInvalid={!!errors.VISION}
                                feedback={errors.VISION}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="中度障礙(看不清報紙的標題，但能辨識物件)" 
                                name="VISION"  
                                value={3}
                                onChange={evt => setField('VISION',evt.target.value)}
                                isInvalid={!!errors.VISION}
                                feedback={errors.VISION}
                            />
                            <br/>
                            <Form.Check 
                                inline
                                type="radio" 
                                label="重度障礙(辨識物件有困難，但眼睛能隨物件移動)" 
                                name="VISION"  
                                value={4}
                                onChange={evt => setField('VISION',evt.target.value)}
                                isInvalid={!!errors.VISION}
                                feedback={errors.VISION}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="極重度障礙(僅能見到光、顏色或形狀，眼睛不能隨物件移動)" 
                                name="VISION"  
                                value={5}
                                onChange={evt => setField('VISION',evt.target.value)}
                                isInvalid={!!errors.VISION}
                                feedback={errors.VISION}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="失明" 
                                name="VISION"  
                                value={6}
                                onChange={evt => setField('VISION',evt.target.value)}
                                isInvalid={!!errors.VISION}
                                feedback={errors.VISION}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.VISION}
                                feedback={errors.VISION}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>13.被照顧者的聽力</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="適當(能正常交談、看電視、接電話)" 
                                name="HEARING" 
                                value={1}
                                onChange={evt => setField('HEARING',evt.target.value)}
                                isInvalid={!!errors.HEARING}
                                feedback={errors.HEARING}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="輕度障礙(在吵雜的環境下有障礙)" 
                                name="HEARING"  
                                value={2}
                                onChange={evt => setField('HEARING',evt.target.value)}
                                isInvalid={!!errors.HEARING}
                                feedback={errors.HEARING}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="中度障礙（在安靜的環境下，需要說話者調整音量才可聽見）" 
                                name="HEARING"  
                                value={3}
                                onChange={evt => setField('HEARING',evt.target.value)}
                                isInvalid={!!errors.HEARING}
                                feedback={errors.HEARING}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="重度障礙（喪失聽覺）" 
                                name="HEARING"  
                                value={4}
                                onChange={evt => setField('HEARING',evt.target.value)}
                                isInvalid={!!errors.HEARING}
                                feedback={errors.HEARING}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.HEARING}
                                feedback={errors.HEARING}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>14.被照顧者的穿脫衣物能力</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="可自行穿脫衣褲及鞋襪" 
                                name="E_WEAR" 
                                value={10}
                                onChange={evt => setField('E_WEAR',evt.target.value)}
                                isInvalid={!!errors.E_WEAR}
                                feedback={errors.E_WEAR}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="需協助" 
                                name="E_WEAR"  
                                value={5}
                                onChange={evt => setField('E_WEAR',evt.target.value)}
                                isInvalid={!!errors.E_WEAR}
                                feedback={errors.E_WEAR}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="需完全協助(完全依賴)" 
                                name="E_WEAR"  
                                value={0}
                                onChange={evt => setField('E_WEAR',evt.target.value)}
                                isInvalid={!!errors.E_WEAR}
                                feedback={errors.E_WEAR}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.E_WEAR}
                                feedback={errors.E_WEAR}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>15.被照顧者的小便控制</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="無失禁" 
                                name="E_URINE" 
                                value={10}
                                onChange={evt => setField('E_URINE',evt.target.value)}
                                isInvalid={!!errors.E_URINE}
                                feedback={errors.E_URINE}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="偶爾失禁" 
                                name="E_URINE"  
                                value={5}
                                onChange={evt => setField('E_URINE',evt.target.value)}
                                isInvalid={!!errors.E_URINE}
                                feedback={errors.E_URINE}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="需完全協助(完全依賴)" 
                                name="E_URINE"  
                                value={0}
                                onChange={evt => setField('E_URINE',evt.target.value)}
                                isInvalid={!!errors.E_URINE}
                                feedback={errors.E_URINE}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.E_URINE}
                                feedback={errors.E_URINE}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>16.被照顧者上廁所</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="可自行上下馬桶、整理衣褲、使用衛生紙、沖馬桶或清理便盆(尿壺)" 
                                name="E_TOILET" 
                                value={10}
                                onChange={evt => setField('E_TOILET',evt.target.value)}
                                isInvalid={!!errors.E_TOILET}
                                feedback={errors.E_TOILET}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="需協助整理衣物或使用衛生紙或需協助清理便盆(尿壺)" 
                                name="E_TOILET"  
                                value={5}
                                onChange={evt => setField('E_TOILET',evt.target.value)}
                                isInvalid={!!errors.E_TOILET}
                                feedback={errors.E_TOILET}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="需完全協助(完全依賴)" 
                                name="E_TOILET"  
                                value={0}
                                onChange={evt => setField('E_TOILET',evt.target.value)}
                                isInvalid={!!errors.E_TOILET}
                                feedback={errors.E_TOILET}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.E_TOILET}
                                feedback={errors.E_TOILET}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>17.被照顧者移位能力</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="可自行維持坐姿平衡，移至椅子或用輪椅" 
                                name="E_MOVE" 
                                value={15}
                                onChange={evt => setField('E_MOVE',evt.target.value)}
                                isInvalid={!!errors.E_MOVE}
                                feedback={errors.E_MOVE}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="移位時需少部分協助或提醒" 
                                name="E_MOVE"  
                                value={10}
                                onChange={evt => setField('E_MOVE',evt.target.value)}
                                isInvalid={!!errors.E_MOVE}
                                feedback={errors.E_MOVE}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="可自行維持坐姿平衡，離床需大部分協助" 
                                name="E_MOVE"  
                                value={5}
                                onChange={evt => setField('E_MOVE',evt.target.value)}
                                isInvalid={!!errors.E_MOVE}
                                feedback={errors.E_MOVE}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="需完全協助(完全依賴)" 
                                name="E_MOVE"  
                                value={0}
                                onChange={evt => setField('E_MOVE',evt.target.value)}
                                isInvalid={!!errors.E_MOVE}
                                feedback={errors.E_MOVE}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.E_MOVE}
                                feedback={errors.E_MOVE}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>18.被照顧者走路能力</Form.Label>
                        <Form.Group className="mb-3">
                        <Form.Check 
                                inline
                                type="radio" 
                                label="獨立走 50 公尺以上(可用輔具)" 
                                name="E_WALK" 
                                value={15}
                                onChange={evt => setField('E_WALK',evt.target.value)}
                                isInvalid={!!errors.E_WALK}
                                feedback={errors.E_WALK}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="需協助扶持走 50 公尺以上" 
                                name="E_WALK"  
                                value={10}
                                onChange={evt => setField('E_WALK',evt.target.value)}
                                isInvalid={!!errors.E_WALK}
                                feedback={errors.E_WALK}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="不能步行 50 公尺，但能操縱輪椅 50 公尺" 
                                name="E_WALK"  
                                value={5}
                                onChange={evt => setField('E_WALK',evt.target.value)}
                                isInvalid={!!errors.E_WALK}
                                feedback={errors.E_WALK}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="不能步行 50 公尺，且無法操縱輪椅" 
                                name="E_WALK"  
                                value={0}
                                onChange={evt => setField('E_WALK',evt.target.value)}
                                isInvalid={!!errors.E_WALK}
                                feedback={errors.E_WALK}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.E_WALK}
                                feedback={errors.E_WALK}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>19.被照顧者能否自己一個人洗(曬)衣服</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="自己清洗所有衣物；需部份協助(例如需協助晾曬衣物或洗滌厚重衣物)" 
                                name="CLEANING" 
                                value={5}
                                onChange={evt => setField('CLEANING',evt.target.value)}
                                isInvalid={!!errors.CLEANING}
                                feedback={errors.CLEANING}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="需完全協助(完全依賴)" 
                                name="CLEANING"  
                                value={0}
                                onChange={evt => setField('CLEANING',evt.target.value)}
                                isInvalid={!!errors.CLEANING}
                                feedback={errors.CLEANING}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.CLEANING}
                                feedback={errors.CLEANING}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>20.被照顧者的身體疼痛的程度(過去1個月)</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="完全無疼痛" 
                                name="G1A" 
                                value={1}
                                onChange={evt => setField('G1A',evt.target.value)}
                                isInvalid={!!errors.G1A}
                                feedback={errors.G1A}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="輕微的疼痛" 
                                name="G1A"  
                                value={2}
                                onChange={evt => setField('G1A',evt.target.value)}
                                isInvalid={!!errors.G1A}
                                feedback={errors.G1A}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="中度疼痛" 
                                name="G1A"  
                                value={3}
                                onChange={evt => setField('G1A',evt.target.value)}
                                isInvalid={!!errors.G1A}
                                feedback={errors.G1A}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="較嚴重的疼痛(尚可忍受的程度)" 
                                name="G1A"  
                                value={4}
                                onChange={evt => setField('G1A',evt.target.value)}
                                isInvalid={!!errors.G1A}
                                feedback={errors.G1A}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="非常嚴重的疼痛(無法忍受的程度)" 
                                name="G1A"  
                                value={5}
                                onChange={evt => setField('G1A',evt.target.value)}
                                isInvalid={!!errors.G1A}
                                feedback={errors.G1A}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.G1A}
                                feedback={errors.G1A}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>21.被照顧者是否因關節僵硬受限制，以致影響日常生活功能</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="否，沒有影響日常生活功能" 
                                name="G3A" 
                                value={1}
                                onChange={evt => setField('G3A',evt.target.value)}
                                isInvalid={!!errors.G3A}
                                feedback={errors.G3A}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="是，有影響日常生活功能" 
                                name="G3A"  
                                value={2}
                                onChange={evt => setField('G3A',evt.target.value)}
                                isInvalid={!!errors.G3A}
                                feedback={errors.G3A}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.G3A}
                                feedback={errors.G3A}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>22.被照顧者的身高(請輸入整數)</Form.Label>
                            <Form.Control type="text" required
                                onChange={evt => setField('TALL',evt.target.value)} 
                                isInvalid={!!errors.TALL}/>
                            <Form.Control.Feedback type='invalid'>{ errors.TALL }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>23.被照顧者的體重(請輸入整數)</Form.Label>
                            <Form.Control type="text" required
                                onChange={evt => setField('WEIGH',evt.target.value)} 
                                isInvalid={!!errors.WEIGH}/>
                            <Form.Control.Feedback type='invalid'>{ errors.WEIGH }</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Label>24.被照顧者的一般飲食狀況(以週為單位)</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="未吃完，每餐進食量不超過整餐的 1/3，或除正餐外沒有補充任何點心，或被禁食（或採清流質或靜脈輸液超過 5 天），或灌食自製管灌飲食小於 1600c.c./日，或攝取的管灌飲食小於 800 大卡/日。" 
                                name="G4C" 
                                value={1}
                                onChange={evt => setField('G4C',evt.target.value)}
                                isInvalid={!!errors.G4C}
                                feedback={errors.G4C}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="未吃完，每餐進食量不超過整餐的 1/2，或偶爾吃點心，或灌食自製管灌飲食小於 2000c.c./日，或攝取的管灌飲食小於 1000 大卡/日。" 
                                name="G4C"  
                                value={2}
                                onChange={evt => setField('G4C',evt.target.value)}
                                isInvalid={!!errors.G4C}
                                feedback={errors.G4C}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="未吃完，每餐進食量超過整餐的 1/2，或有時拒絕用餐但會吃點心，或灌食自製管灌飲食小於 2400c.c./日，或攝取的管灌飲食小於 1200 大卡/日。" 
                                name="G4C"  
                                value={3}
                                onChange={evt => setField('G4C',evt.target.value)}
                                isInvalid={!!errors.G4C}
                                feedback={errors.G4C}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="每餐吃完，從不拒絕用餐，或不需任何補充食物，或灌食自製管灌飲食大於2400c.c./日，或採用管灌飲食（或靜脈營養）大於 1200 大卡/日。" 
                                name="G4C"  
                                value={4}
                                onChange={evt => setField('G4C',evt.target.value)}
                                isInvalid={!!errors.G4C}
                                feedback={errors.G4C}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.G4C}
                                feedback={errors.G4C}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>25.被照顧者是否有提不起勁來做事的感覺</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="是" 
                                name="G4D3"
                                value={1}
                                onChange={evt => setField('G4D3',evt.target.value)}
                                isInvalid={!!errors.G4D3}
                                feedback={errors.G4D3}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="否" 
                                name="G4D3" 
                                value={0}
                                onChange={evt => setField('G4D3',evt.target.value)}
                                isInvalid={!!errors.G4D3}
                                feedback={errors.G4D3}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.G4D3}
                                feedback={errors.G4D3}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>26.被照顧者尋求必要醫療時，是否需要服務介入協助</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="需協助" 
                                name="G4F" 
                                value={1}
                                onChange={evt => setField('G4F',evt.target.value)}
                                isInvalid={!!errors.G4F}
                                feedback={errors.G4F}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="不需要協助" 
                                name="G4F"  
                                value={0}
                                onChange={evt => setField('G4F',evt.target.value)}
                                isInvalid={!!errors.G4F}
                                feedback={errors.G4F}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.G4F}
                                feedback={errors.G4F}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>27.被照顧者是否能維持站立的平衡</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="正常，不靠扶持能站立達一分鐘以上" 
                                name="G8B" 
                                value={1}
                                onChange={evt => setField('G8B',evt.target.value)}
                                isInvalid={!!errors.G8B}
                                feedback={errors.G8B}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="較差，不靠扶持只能站立十秒鐘至一分鐘" 
                                name="G8B"  
                                value={2}
                                onChange={evt => setField('G8B',evt.target.value)}
                                isInvalid={!!errors.G8B}
                                feedback={errors.G8B}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="極差，不靠扶持只能站立少於十秒鐘(包括完全無法站)" 
                                name="G8B"  
                                value={3}
                                onChange={evt => setField('G8B',evt.target.value)}
                                isInvalid={!!errors.G8B}
                                feedback={errors.G8B}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.G8B}
                                feedback={errors.G8B}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>28.被照顧者有沒有跌倒或摔倒過(過去12個月內)</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="沒有跌倒或摔倒過" 
                                name="G8C" 
                                value={1}
                                onChange={evt => setField('G8C',evt.target.value)}
                                isInvalid={!!errors.G8C}
                                feedback={errors.G8C}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="跌倒或摔倒1次" 
                                name="G8C"  
                                value={2}
                                onChange={evt => setField('G8C',evt.target.value)}
                                isInvalid={!!errors.G8C}
                                feedback={errors.G8C}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="跌倒或摔倒2次以上(含 2 次)" 
                                name="G8C"  
                                value={3}
                                onChange={evt => setField('G8C',evt.target.value)}
                                isInvalid={!!errors.G8C}
                                feedback={errors.G8C}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.G8C}
                                feedback={errors.G8C}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>29.被照顧者對於危險的認知</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="有能力：可以認識日常生活危險來源" 
                                name="G8E" 
                                value={1}
                                onChange={evt => setField('G8E',evt.target.value)}
                                isInvalid={!!errors.G8E}
                                feedback={errors.G8E}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="大部分有能力：可以認識大部分於日常生活中的危險來源" 
                                name="G8E"  
                                value={2}
                                onChange={evt => setField('G8E',evt.target.value)}
                                isInvalid={!!errors.G8E}
                                feedback={errors.G8E}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="小範圍有能力：對於常會碰到的危險（尤其是住家環境中會出現的危險），卻常不認為那是危險" 
                                name="G8E"  
                                value={3}
                                onChange={evt => setField('G8E',evt.target.value)}
                                isInvalid={!!errors.G8E}
                                feedback={errors.G8E}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="無能力：完全不自知有危險" 
                                name="G8E"  
                                value={4}
                                onChange={evt => setField('G8E',evt.target.value)}
                                isInvalid={!!errors.G8E}
                                feedback={errors.G8E}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.G8E}
                                feedback={errors.G8E}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>30.被照顧者聯絡親朋好友的頻率</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="沒有親朋好友" 
                                name="H2A" 
                                value={1}
                                onChange={evt => setField('H2A',evt.target.value)}
                                isInvalid={!!errors.H2A}
                                feedback={errors.H2A}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="從不聯絡" 
                                name="H2A"  
                                value={2}
                                onChange={evt => setField('H2A',evt.target.value)}
                                isInvalid={!!errors.H2A}
                                feedback={errors.H2A}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="很少聯絡" 
                                name="H2A"  
                                value={3}
                                onChange={evt => setField('H2A',evt.target.value)}
                                isInvalid={!!errors.H2A}
                                feedback={errors.H2A}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="每個月至少1次" 
                                name="H2A"  
                                value={4}
                                onChange={evt => setField('H2A',evt.target.value)}
                                isInvalid={!!errors.H2A}
                                feedback={errors.H2A}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="每週至少1次" 
                                name="H2A"  
                                value={5}
                                onChange={evt => setField('H2A',evt.target.value)}
                                isInvalid={!!errors.H2A}
                                feedback={errors.H2A}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="每週至少連絡2-3次" 
                                name="H2A"  
                                value={6}
                                onChange={evt => setField('H2A',evt.target.value)}
                                isInvalid={!!errors.H2A}
                                feedback={errors.H2A}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="每天聯絡" 
                                name="H2A"  
                                value={7}
                                onChange={evt => setField('H2A',evt.target.value)}
                                isInvalid={!!errors.H2A}
                                feedback={errors.H2A}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.H2A}
                                feedback={errors.H2A}
                                feedbackType="invalid"
                            />
                        </Form.Group>

                        <Form.Label>31.被照顧者是否有失智症之精神行為症狀(BPSD)<Button variant="link" onClick={handleBPSDOpen} style={{ padding: 0 }}>BPSD說明</Button></Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Check inline
                                type="radio" 
                                label="是" 
                                name="SOLITARY" 
                                value={2}
                                onChange={evt => setField('SOLITARY',evt.target.value)}
                                isInvalid={!!errors.SOLITARY}
                                feedback={errors.SOLITARY}
                            />
                            <Form.Check inline
                                type="radio" 
                                label="否" 
                                name="SOLITARY"  
                                value={1}
                                onChange={evt => setField('SOLITARY',evt.target.value)}
                                isInvalid={!!errors.SOLITARY}
                                feedback={errors.SOLITARY}
                            />
                            <Form.Check
                                type="radio"
                                hidden
                                isInvalid={!!errors.SOLITARY}
                                feedback={errors.SOLITARY}
                                feedbackType="invalid"
                            />
                        </Form.Group>


                        <Button variant="primary" type="button"
                            onClick={handleServiceForm}>送出</Button>

                        <Modal show={BPSDshow} onHide={handleBPSDClose} size="lg">
                            <Modal.Header closeButton>
                            <Modal.Title>BPSD行為</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                1. 遊走<br/>
                                2. 日夜顛倒/作息混亂<br/>
                                3. 語言攻擊行為<br/>
                                4. 肢體攻擊行為<br/>
                                5. 干擾行為，例如：製造奇怪聲響、弄髒自己、暴食、亂丟食物、亂翻他人物品、儲藏物品、社交上不恰當的行為、偷竊行為等<br/>
                                6. 抗拒照護<br/>
                                7. 妄想<br/>
                                8. 幻覺<br/>
                                9. 恐懼或焦慮<br/>
                                10. 憂鬱及負性症狀<br/>
                                11. 自傷行為及自殺(包含意念及行為)<br/>
                                12. 重複行為<br/>
                                13. 對物品的攻擊行為，例如:個案是否曾摔門、踢或破壞家具，或者縱火、破壞物品、丟東西<br/>
                                14. 其他不適當以及不潔行為，例如:隨地吐口水或者玩弄自己的排泄物、公開場合寬衣解帶、公開場合自慰或言語性暗示或挑逗、異食癖，或其他讓人感到難堪的事<br/>
                            </Modal.Body>
                        </Modal>

                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ServiceFormPage;